<mat-card class="login-card" *ngIf="isLoaded">
  <mat-card-header class="login-card-header">
    <mat-card-title>Finish signup</mat-card-title>
  </mat-card-header>
  <div class="login-error" *ngIf="errorMessage">
    <p class="login-error-message">{{ errorMessage }}</p>
  </div>

  <mat-card-content>
    <div class="login-selection">
        <mat-form-field class="login-option">
          <mat-label>Email</mat-label>
          <input matInput value="{{this.email}}" readonly />
        </mat-form-field>
      <form [formGroup]="signupForm" (ngSubmit)="handleSubmit()">

        <mat-form-field class="login-option">
          <mat-label>Password</mat-label>
          <input matInput type="password" placeholder="Enter your password" formControlName="password" [(ngModel)]="password" autofocus required />
          <password-strength-meter [password]="password" (strengthChange)="onPasswordStrengthChange($event)" />
        </mat-form-field>

        <div class="login-option">
          <button mat-button color="primary" [disabled]="this.clicked">Finish Signup</button>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
