import {Router, ActivatedRoute, Params} from '@angular/router';
import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatCardModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}
  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });
  errorMessage = null; // Reset error message before sending request
  loginService: LoginService = inject(LoginService);
  async handleSubmit() {
    this.errorMessage = null; // Reset error message before sending request
    const response = await this.loginService.SubmitLogin(this.loginForm.value.email!, this.loginForm.value.password!);
    const data = await response.json();
    console.log('print data');
    console.log(data);
    if (!data.success) {
      this.errorMessage = data.error;
      return;
    }
    localStorage.setItem('api_key', data.api_key);
    this.router.navigate(['/profile']);
  }
}
