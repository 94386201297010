import {Router, ActivatedRoute, Params} from '@angular/router';
import { AfterViewChecked, ViewChild, Component, OnInit, inject, ElementRef, Directive} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { SignupService } from './signup.service';
import { PasswordStrengthMeterComponent } from 'angular-password-strength-meter'

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatCardModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    PasswordStrengthMeterComponent
  ],
  templateUrl: './signup.component.html',
  styleUrl: '../login/login.component.css'
})
export class SignupComponent implements OnInit {
  errorMessage = null; // Reset error message before sending request
  signupService: SignupService = inject(SignupService);
  email = "";
  isLoaded = false;
  password='';
  sessionId = '';
  clicked = false;
  score: number | null = null;
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}
  ngOnInit(): void {
    // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
      this.activatedRoute.params.subscribe(async params => {
        //const sessionId = params['session_id'];
        if (localStorage.getItem('api_key') === null) {
          const sessionIdFragment = window.location.hash;
          this.sessionId = sessionIdFragment.substring(1);
          localStorage.setItem('api_key', this.sessionId);
          window.location.hash = "";
        } else {
          this.sessionId = localStorage.getItem('api_key') ?? '';
        }
        console.log(this.sessionId);
        this.email = await this.signupService.GetCustomerEmail(this.sessionId);
        console.log(this.email);
        this.isLoaded = true;
      });
  }

  //@ViewChild('password', {static: false}) passwordRef!: ElementRef;
  //ngAfterViewChecked(): void {
  //  this.passwordRef.nativeElement.focus(); // Focus on the pw input field
  //}

  signupForm = new FormGroup({
    password: new FormControl(''),
  });

  async handleSubmit() {
    this.clicked = true;
    this.errorMessage = null; // Reset error message before sending request
    console.log(this.score);
    console.log("log this.score");
    if (this.score !== null && this.score < 3) {
      const msg: any = "Password not complex enough";
      this.errorMessage = msg;
      this.clicked = false;
      return;
    }
    const api_key = localStorage.getItem('api_key') ?? '';
    const response = await this.signupService.SubmitSignup(this.email, this.signupForm.value.password!, api_key);
    const data = await response.json();
    console.log('print data');
    console.log(data);
    if (!data.success) {
      this.errorMessage = data.error;
      this.clicked = false;
      return;
    }
    this.router.navigate(['/profile']);
  }
   public onPasswordStrengthChange(score: number | null) {
    this.score = score;
    console.log(this.score);
  }

}
