import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ProfileComponent } from './profile/profile.component';
import { ImagesComponent } from './profile/images/images.component';
import { BookmarksComponent } from './profile/bookmarks/bookmarks.component';
import { SettingsComponent } from './profile/settings/settings.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { R404Component } from './r404/r404.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';

export const routes: Routes = [
  {
    path: '',
    title: 'Perfect Recall',
    component: HomeComponent,
  },
  {
    path: '404',
    title: 'Perfect Recall',
    component: R404Component,
  },
  {
    path: 'login',
    title: 'Perfect Recall',
    component: LoginComponent,
  },
  {
    path: 'signup',
    title: 'Perfect Recall',
    component: SignupComponent,
  },
  {
    path: 'profile',
    title: 'Perfect Recall',
    component: ProfileComponent,
  },
  {
    path: 'profile/pages',
    title: 'Perfect Recall',
    component: ProfileComponent,
  },
  {
    path: 'profile/images',
    title: 'Perfect Recall',
    component: ImagesComponent,
  },
  {
    path: 'profile/bookmarks',
    title: 'Perfect Recall',
    component: BookmarksComponent,
  },
  {
    path: 'profile/settings',
    title: 'Perfect Recall',
    component: SettingsComponent,
  },
  {
    path: 'privacy-policy',
    title: 'JourneyAL',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms-of-service',
    title: 'JourneyAL',
    component: TermsOfServiceComponent,
  },
  {
    path: 'contact',
    title: 'JourneyAL',
    component: ContactComponent,
  },
  {
    path: 'about',
    title: 'JourneyAL',
    component: AboutComponent,
  }
];

