<div class="footer-pages wrap container-fluid">
  <br /><br />
  <h1>Privacy Policy</h1>
  <br /><br />
  <h2>Our Commitment To Privacy</h2>
  <p>Your privacy is important to us. To better protect your privacy we provide
  this notice explaining our online information practices and the choices you
  can make about the way your information is collected and used.</p>
  <br /><br />
  <h2>The Information We Collect:</h2>
  <p>This notice applies to all information collected or submitted on this website.
  On some pages, you can make requests and interact with the site. The types of personal information collected at these pages are:</p>
  <p>Email</p>
  <br />
  <h3>If you have installed the browser extension:</h3>
  <p>Text from pages sent to us via the browser extension, if installed</p>
  <p>URLs from the browser extension, if installed</p>
  <p>Images from pages sent to us via the browser extension, if installed</p>
  <p>Bookmarks sent to us via the browser extension, if installed</p>

  <br /><br />
  <h2>The Information We DONT Collect:</h2><p>Credit/Debit Card Information (processed by Stripe)<br/>Name (processed by Stripe)<br/>Address (processed by Stripe)</p>
  <br /><br />
  <h2>The Way We Use Information:</h2>
  <p>The email address you provide is used for correspondence and to log into the site. </p>
  <p>We never use or share the personally identifiable information provided
    to us online in ways unrelated to the ones described above without also providing
    you an opportunity to opt-out or otherwise prohibit such unrelated uses.</p>
  <br /><br />
  <h2>Our Commitment To Data Security</h2><p>To prevent unauthorized access, maintain data accuracy, and ensure the correct
    use of information, we have put in place appropriate physical, electronic,
    and managerial procedures to safeguard and secure the information we collect
    online.</p>
  <br /><br />
    <h2>Our Commitment To Children's Privacy:</h2><p>Protecting the privacy of the very young is especially important. For that
    reason, we never collect or maintain information at our website from those
    we actually know are under 13, and no part of our website is structured to
    attract anyone under 13.</p>
</div>