<div class="footer-pages wrap container-fluid">
  <h1>Terms of Service</h1>
  <h3>1. Introduction</h3>
  <p>The Website Owner, including subsidiaries and affiliates ("Website"
    or "Website Owner" or "we" or "us" or "our")
    provides the information contained on this website or any of the pages
    comprising the website ("website") to visitors ("visitors")
    (cumulatively referred to as "you" or "your" hereinafter)
    subject to the terms and conditions set out in these website terms and
    conditions, the privacy policy
    and any other relevant terms and conditions, policies and notices which
    may be applicable to a specific section or module of this website.</p>
  <h3>2. Information on the Website</h3>
  <p>Whilst every effort is made to update the information contained on this
    website, neither the Website Owner nor any third party or data or content
    provider make any representations or warranties, whether express, implied
    in law or residual, as to the sequence, accuracy, completeness or reliability
    of information, opinions, any share price information, research information,
    data and/or content contained on the website (including but not limited
    to any information which may be provided by any third party or data or
    content providers) ("information") and shall not be bound in
    any manner by any information contained on the website. The Website Owner
    reserves the right at any time to change or discontinue without notice,
    any aspect or feature of this website. No information shall be construed
    as advice and information is offered for information purposes only and
    is not intended for trading purposes. You and your company rely on the
    information contained on this website at your own risk. If you find an
    error or omission at this site, please let
    us know.</p>
  <h3>3. Trade Marks</h3>
  <p>The trade marks, names, logos and service marks (collectively "trade
    marks") displayed on this website are registered and unregistered
    trade marks of the Website Owner. Nothing contained on this website should
    be construed as granting any licence or right to use any trade mark without
    the prior written permission of the Website Owner.</p>
  <h3>4. External Links</h3>
  <p>External links may be provided for your convenience, but they are beyond
    the control of the Website Owner and no representation is made as to their
    content. Use or reliance on any external links and the content thereon
    provided is at your own risk. When visiting external links you must refer
    to that external websites terms and conditions of use.</p>
  <h3>5. Public Forums and User Submissions</h3>
  <p>The Website Owner is not responsible for any material submitted to the
    public areas by you (which include bulletin boards, hosted pages, chat
    rooms, or any other public area found on the website. Any material (whether
    submitted by you or any other user) is not endorsed, reviewed or approved
    by the Website Owner. The Website Owner reserves the right to remove any
    material submitted or posted by you in the public areas, without notice
    to you, if it becomes aware and determines, in its sole and absolute discretion
    that you are or there is the likelihood that you may, including but not
    limited to -</p>
  <p><b>5.1</b> defame, abuse, harass, stalk, threaten or otherwise violate
    the rights of other users or any third parties;</p>
  <p><b>5.2</b> publish, post, distribute or disseminate any defamatory, obscene,
    indecent or unlawful material or information;</p>
  <p><b>5.3</b> post or upload files that contain viruses, corrupted files
    or any other similar software or programmes that may damage the operation
    of the Website Owner's and/or a third party's computer system and/or network;</p>
  <p><b>5.4</b> violate any copyright, trade mark, other applicable
    international laws or intellectual property rights of the
    Website Owner or any other third party;</p>
  <p><b>5.5</b> submit contents containing marketing or promotional material
    which is intended to solicit business.</p>
  <h3>6. Specific Use</h3>
  <p>You further agree not to use the website to send or post any message
    or material that is unlawful, harassing, defamatory, abusive, indecent,
    threatening, harmful, vulgar, obscene, sexually orientated, racially offensive,
    profane, pornographic or violates any applicable law and you hereby indemnify
    the Website Owner against any loss, liability, damage or expense of whatever
    nature which the Website Owner or any third party may suffer which is
    caused by or attributable to, whether directly or indirectly, your use
    of the website to send or post any such message or material.</p>
  <h3>7. Warranties</h3>
  <p>The Website Owner makes no warranties, representations, statements or
    guarantees (whether express, implied in law or residual) regarding the
    website, the information contained on the website, your or your company's
    personal information or material and information transmitted over our
    system.</p>
  <h3>8. Disclaimer of Liability.</h3>
  <p>The Website Owner shall not be responsible for and disclaims all liability
    for any loss, liability, damage (whether direct, indirect or consequential),
    personal injury or expense of any nature whatsoever which may be suffered
    by you or any third party (including your company), as a result of or
    which may be attributable, directly or indirectly, to your access and
    use of the website, any information contained on the website, your or
    your company's personal information or material and information transmitted
    over our system. In particular, neither the Website Owner nor any third
    party or data or content provider shall be liable in any way to you or
    to any other person, firm or corporation whatsoever for any loss, liability,
    damage (whether direct or consequential), personal injury or expense of
    any nature whatsoever arising from any delays, inaccuracies, errors in,
    or omission of any share price information or the transmission thereof,
    or for any actions taken in reliance thereon or occasioned thereby or
    by reason of non-performance or interruption, or termination thereof.</p>
  <h3>9. Use of the Website.</h3>
  <p>The Website Owner does not make any warranty or representation that information
    on the website is appropriate for use in any jurisdiction (other than
    the USA ). By accessing the website, you warrant and represent
    to the Website Owner that you are legally entitled to do so and to make
    use of information made available via the website.</p>
  <h3>10. General</h3>
  <h3>10.1 Entire Agreement. </h3>
  <p>These website terms and conditions constitute the sole record of the
    agreement between you and the Website Owner in relation to your use of
    the website. Neither you nor the Website Owner shall be bound by any express
    tacit or implied representation, warranty, promise or the like not recorded
    herein. Unless otherwise specifically stated these website terms and conditions
    supersede and replace all prior commitments, undertakings or representations,
    whether written or oral, between you and the Website Owner in respect
    of your use of the website.</p>
  <h3>10.2 Alteration</h3>
  <p>the Website Owner may at any time modify any relevant terms and conditions,
    policies or notices. You acknowledge that by visiting the website from
    time to time, you shall become bound to the current version of the relevant
    terms and conditions (the "current version") and, unless stated
    in the current version, all previous versions shall be superseded by the
    current version. You shall be responsible for reviewing the then current
    version each time you visit the website.</p>
  <h3>10.3 Conflict. </h3>
  <p>Where any conflict or contradiction appears between the provisions of
    these website terms and conditions and any other relevant terms and conditions,
    policies or notices, the other relevant terms and conditions, policies
    or notices which relate specifically to a particular section or module
    of the website shall prevail in respect of your use of the relevant section
    or module of the website.</p>
  <h3>10.4 Waiver.</h3>
  <p>No indulgence or extension of time which either you or the Website Owner
    may grant to the other will constitute a waiver of or, whether by estoppel
    or otherwise, limit any of the existing or future rights of the grantor
    in terms hereof, save in the event or to the extent that the grantor has
    signed a written document expressly waiving or limiting such rights.</p>
  <h3>10.5 Cession. </h3>
  <p>The Website Owner shall be entitled to cede, assign and delegate all
    or any of its rights and obligations in terms of any relevant terms and
    conditions, policies and notices to any third party.</p>
  <h3>10.6 Severability.</h3>
  <p>All provisions of any relevant terms and conditions, policies and notices
    are, notwithstanding the manner in which they have been grouped together
    or linked grammatically, severable from each other. Any provision of any
    relevant terms and conditions, policies and notices, which is or becomes
    unenforceable in any jurisdiction, whether due to voidness, invalidity,
    illegality, unlawfulness or for any reason whatever, shall, in such jurisdiction
    only and only to the extent that it is so unenforceable, be treated as
    pro non scripto and the remaining provisions of any relevant terms and
    conditions, policies and notices shall remain in full force and effect.</p>
  <h3>10.7 Applicable laws. </h3>
  <p>Any relevant terms and conditions, policies and notices shall be governed
    by and construed in accordance with the laws of the USA without
    giving effect to any principles of conflict of law. You hereby consent
    to the exclusive jurisdiction of the USA
    in respect of any disputes arising in connection with the website, or
    any relevant terms and conditions, policies and notices or any matter
    related to or in connection therewith.</p>
  <h3>10.8 Comments or Questions. </h3>
  <p>If you have any questions, comments or concerns arising from the website,
    the privacy policy or any other
    relevant terms and conditions, policies and notices or the way in which
    we are handling your personal information please contact
    us.<br /></p>
  <h3>11 Pricing</h3>
  <h5>11.1 Pricing Terms</h5>
  <p>Pricing is subject to change and users will not necessarily be notified of the change.
    To find the current prices of space users can check the individual pricing pages.</p>
  <h5></h5>
  <p></p>
  <h5>11.2 Charging</h5>
  <p>Purchasers will be charged a one time fee processed by Stripe. </p>
  <p>The option for a recurring fee processed by Stripe. Is not available at this time</p>
  <h5>11.3 Expiring</h5>
  <p>Subscribers are free to cancel their subscription at any time once a subscription option is available.</p>
  <h5>11.4 Refund</h5>
  <p>Refunds will be decided on a case by case basis.</p>
  <h5>11.5 Cancellation of Accounts</h5>
  <p>If an account is canceled by removing the account through the cancel account button no refund will be issued for
    any
    previously purchased items. All future subscriptions will be cancelled if a subscription option is available and
    subscribed to.</p>
  <h5>11.6 Warranty</h5>
  <p>There will be no warranty express or implied for any services that a user purchases from journeyal.com,
    furthermore a purchase from journeyal.com does not
    guarantee an account or fitness for a particular purpose.</p>
</div>