import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  url = 'https://devbox.abemassry.com/api';

  async SubmitLogin(email: string="", password: string="") {
    const body = {"email": email, "password": password};
    return await fetch(`${this.url}/login`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  constructor() { }
}
