import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BookmarksService {
  url = 'https://devbox.abemassry.com/api';

  async getBookmarks(api_key: string = "", count: number = 25, pageIndex: number = 0, search_term: string = "") {
    const body = {
      "api_key": api_key,
      "count": count,
      "page_index": pageIndex,
      "search_term": search_term
    };
    return await fetch(`${this.url}/bookmarks`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  async getBookmarksLength(api_key: string = "", search_term: string = "") {
    const body = {
      "api_key": api_key,
      "search_term": search_term
    };
    return await fetch(`${this.url}/bookmarks/length`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  async deleteBookmarkApi(api_key: string = "", url: string) {
    const body = { "api_key": api_key, "url": url };
    return await fetch(`${this.url}/bookmark/delete`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  constructor() { }
}
