<mat-sidenav-container class="example-container" autosize>
  <mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true">
    <mat-nav-list>
      <mat-list-item routerLink='/profile/pages'><mat-icon
          class="mat-icon mat-list-icon material-icons">article</mat-icon> Pages</mat-list-item>
      <mat-list-item routerLink='/profile/images'><mat-icon class="mat-icon mat-list-icon material-icons">photo_library</mat-icon> Images</mat-list-item>
      <mat-list-item routerLink='/profile/bookmarks'><mat-icon
          class="mat-icon mat-list-icon material-icons">bookmarks</mat-icon>
        Bookmarks</mat-list-item>
      <mat-list-item class="profile-highlight" routerLink='/profile/settings'><mat-icon
          class="mat-icon mat-list-icon material-icons">settings</mat-icon> Settings</mat-list-item>
      <br />
    </mat-nav-list>
    <mat-nav-list>
    </mat-nav-list>
  </mat-sidenav>

  <div class="example-sidenav-content">
    <h3>Block List</h3>
    <mat-list *ngFor="let item of items">
      <div class="page-item-parent">
        <div class="page-item">
          <mat-list-item lines="3">
            <span matListItemTitle><a href="{{ item.url }}">{{ item.url }}</a></span>{{
            item.body }}
          </mat-list-item>
        </div>
        <div class="action-icons">
          <button mat-icon-button aria-label="Delete" (click)="deleteBlock(item.url)">
            <mat-icon class="mat-icon mat-list-icon material-icons">delete_forever</mat-icon>
          </button>
        </div>
      </div>
    </mat-list>
      <div>
        Email: {{ this.email }}
      </div>
      <button mat-raised-button (click)="signOut()">Sign Out</button>
  </div>
</mat-sidenav-container>
