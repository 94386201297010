<mat-sidenav-container class="example-container" autosize>
	<mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true">
		<mat-nav-list>
            <mat-list-item class="profile-highlight" routerLink='/profile/pages'><mat-icon class="mat-icon mat-list-icon material-icons">article</mat-icon> Pages</mat-list-item>
            <mat-list-item routerLink='/profile/images'><mat-icon class="mat-icon mat-list-icon material-icons">photo_library</mat-icon> Images</mat-list-item>
            <mat-list-item routerLink='/profile/bookmarks'><mat-icon class="mat-icon mat-list-icon material-icons">bookmarks</mat-icon> Bookmarks</mat-list-item>
            <mat-list-item routerLink='/profile/settings'><mat-icon class="mat-icon mat-list-icon material-icons">settings</mat-icon> Settings</mat-list-item>
            <br />
		</mat-nav-list>
    <mat-nav-list>
</mat-nav-list>
	</mat-sidenav>

  <div class="example-sidenav-content">

    <mat-paginator #paginator
             class="demo-paginator"
             (page)="handlePageEvent($event)"
             [length]="length"
             [pageSize]="pageSize"
             [disabled]="disabled"
             [showFirstLastButtons]="showFirstLastButtons"
             [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
             [hidePageSize]="hidePageSize"
             [pageIndex]="pageIndex"
             aria-label="Select page">
    </mat-paginator>
    <div class="example-form">
      <mat-form-field class="example-full-width">
        <mat-label>search</mat-label>
        <input matInput type="text" placeholder="search" formcontrolname="search" (keyup)="onKeySearch($event)" />
      </mat-form-field>
    </div>
    <mat-list *ngFor="let item of items">
      <div class="page-item-parent">
        <div class="page-item">
          <mat-list-item lines="3">
            <span matListItemTitle><a class="page-link" href="{{ item.url }}" target="_blank" rel="noopener noreferrer">{{ item.title }}</a></span>{{ item.body }}
          </mat-list-item>
        </div>
        <div class="action-icons">
          <button mat-icon-button aria-label="Delete this page" (click)="deletePage(item.url)">
            <mat-icon class="mat-icon mat-list-icon material-icons">delete_forever</mat-icon>
          </button>
          <button mat-icon-button aria-label="Block this page and don't recapture" (click)="blockPage(item.url)">
            <mat-icon class="mat-icon mat-list-icon material-icons">block</mat-icon>
          </button>
        </div>
      </div>

    </mat-list>
    <mat-paginator #paginator
               class="demo-paginator"
               (page)="handlePageEvent($event)"
               [length]="length"
               [pageSize]="pageSize"
               [disabled]="disabled"
               [showFirstLastButtons]="showFirstLastButtons"
               [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
               [hidePageSize]="hidePageSize"
               [pageIndex]="pageIndex"
               aria-label="Select page">
    </mat-paginator>
  </div>

</mat-sidenav-container>
