<mat-toolbar color="primary" class="transparent-header">
  <!-- Left side: Logo -->
  <div class="logo-container">
    <a routerLink=''>
      <img src="path/to/your/logo.png" alt="Logo">
    </a>
    <span class="toolbar-button-spacer"></span>
    <button mat-button routerLink="" fragment="download" *ngIf="isHomePage()" fxShow="true" fxHide.lt-md="true">
      Download
    </button>
    <button mat-button routerLink="" fragment="pricing" *ngIf="isHomePage()" fxShow="true" fxHide.lt-md="true">
      Pricing
    </button>
    <button mat-button routerLink="" fragment="features" *ngIf="isHomePage()" fxShow="true" fxHide.lt-md="true">
      Features
    </button>
  </div>

  <!-- Right side: Login/Sign up buttons -->
  <div class="button-container" *ngIf="email !== ''; else loggedOut">
    <button mat-button routerLink='profile'>Profile</button>
  </div>
  <ng-template #loggedOut>
    <button mat-button routerLink='login'>Log In</button>
    <form action="https://devbox.abemassry.com/api/create-checkout-session" method="POST">
      <button mat-button routerLink='purchase' color="accent" type="submit">Purchase</button>
    </form>
  </ng-template>
  <div fxShow="true" fxHide.gt-sm="true">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="" fragment="download">
        <span>Download</span>
      </button>
      <button mat-menu-item routerLink="" fragment="pricing">
        <span>Pricing</span>
      </button>
      <button mat-menu-item routerLink="" fragment="features">
        <span>Features</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>