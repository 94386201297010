import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-r404',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './r404.component.html',
  styleUrl: './r404.component.css'
})
export class R404Component {

}
