import * as i0 from '@angular/core';
import { Directive, Input, HostBinding, EventEmitter, inject, booleanAttribute, Component, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, distinctUntilChanged, debounceTime, of, switchMap } from 'rxjs';
function PasswordStrengthMeterComponent_Conditional_3_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "small", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.feedback.warning, " ");
  }
}
function PasswordStrengthMeterComponent_Conditional_3_Conditional_1_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const suggestion_r4 = ctx.$implicit;
    const $index_r5 = ctx.$index;
    const $count_r7 = ctx.$count;
    i0.ɵɵtextInterpolate2(" ", suggestion_r4, "", $index_r5 === $count_r7 - 1 ? "" : " ", " ");
  }
}
function PasswordStrengthMeterComponent_Conditional_3_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "small", 6);
    i0.ɵɵrepeaterCreate(1, PasswordStrengthMeterComponent_Conditional_3_Conditional_1_For_2_Template, 1, 2, null, null, i0.ɵɵrepeaterTrackByIdentity);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵrepeater(ctx_r2.feedback.suggestions);
  }
}
function PasswordStrengthMeterComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PasswordStrengthMeterComponent_Conditional_3_Conditional_0_Template, 2, 1, "small", 3)(1, PasswordStrengthMeterComponent_Conditional_3_Conditional_1_Template, 3, 0, "small", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵconditional(0, ctx_r0.feedback.warning ? 0 : -1);
    i0.ɵɵadvance(1);
    i0.ɵɵconditional(1, ctx_r0.feedback.suggestions && ctx_r0.feedback.suggestions.length ? 1 : -1);
  }
}
class IPasswordStrengthMeterService {}

/* eslint-disable @angular-eslint/directive-selector */
class PSMProgressBarDirective {
  constructor(renderer, el) {
    this.renderer = renderer;
    this.el = el;
    this.colors = [];
    this.minProgressVal = 0;
    this.maxProgressVal = 100;
    this.currentProgressVal = 0;
    this.dataPasswordStrength = 0;
    this.defaultColors = ['darkred', 'orangered', 'orange', 'yellowgreen', 'green'];
    this.progressBar = this.el.nativeElement;
  }
  ngOnChanges(changes) {
    if (changes['numberOfProgressBarItems']) {
      this.setProgressBarItems();
    }
    this.setProgressBar();
  }
  setProgressBarItems() {
    const progressBarItemContainer = this.progressBar.querySelector('.psm__progress-bar-items');
    const width = 100 / this.numberOfProgressBarItems;
    progressBarItemContainer?.childNodes.forEach(item => {
      this.renderer.removeChild(progressBarItemContainer, item);
    });
    Array(this.numberOfProgressBarItems).fill(1).forEach(() => {
      const progressBarItem = this.renderer.createElement('div');
      this.renderer.addClass(progressBarItem, 'psm__progress-bar-item');
      this.renderer.setStyle(progressBarItem, 'width', `${width}%`);
      this.renderer.appendChild(progressBarItemContainer, progressBarItem);
    });
  }
  setProgressBar() {
    const progressBarOverlayWidth = this.getFillMeterWidth(this.passwordStrength);
    const progressBarOverlayWidthInPx = `${progressBarOverlayWidth}%`;
    const progressLevelBasedOnItems = progressBarOverlayWidth / 100 * this.numberOfProgressBarItems;
    const progressBarOverlayColor = this.getMeterFillColor(progressLevelBasedOnItems);
    this.dataPasswordStrength = this.passwordStrength || 0;
    this.currentProgressVal = progressBarOverlayWidth;
    const overlayElement = this.progressBar.querySelector('.psm__progress-bar-overlay');
    if (overlayElement) {
      this.renderer.setStyle(overlayElement, 'width', progressBarOverlayWidthInPx);
      this.renderer.setStyle(overlayElement, 'background-color', progressBarOverlayColor);
    }
  }
  getFillMeterWidth(strength) {
    if (strength === null || strength === undefined) {
      return 0;
    }
    const strengthInPercentage = strength !== null ? (strength + 1) / 5 * 100 : 0;
    const roundedStrengthInPercentage = this.getRoundedStrength(strengthInPercentage, 100 / this.numberOfProgressBarItems);
    return roundedStrengthInPercentage;
  }
  getMeterFillColor(progressLevel) {
    if (!progressLevel || progressLevel <= 0 || progressLevel > this.colors.length && progressLevel > this.defaultColors.length) {
      return this.colors[0] ? this.colors[0] : this.defaultColors[0];
    }
    const index = progressLevel - 1;
    return this.colors[index] ? this.colors[index] : this.defaultColors[index];
  }
  getRoundedStrength(strength, roundTo) {
    return Math.round(strength / roundTo) * roundTo;
  }
  static {
    this.ɵfac = function PSMProgressBarDirective_Factory(t) {
      return new (t || PSMProgressBarDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PSMProgressBarDirective,
      selectors: [["", 8, "psm__progress-bar"]],
      hostVars: 4,
      hostBindings: function PSMProgressBarDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("aria-valuemin", ctx.minProgressVal)("aria-valuemax", ctx.maxProgressVal)("aria-valuenow", ctx.currentProgressVal)("data-strength", ctx.dataPasswordStrength);
        }
      },
      inputs: {
        numberOfProgressBarItems: "numberOfProgressBarItems",
        passwordStrength: "passwordStrength",
        colors: "colors"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PSMProgressBarDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '.psm__progress-bar'
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }], {
    numberOfProgressBarItems: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    passwordStrength: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    colors: [{
      type: Input
    }],
    minProgressVal: [{
      type: HostBinding,
      args: ['attr.aria-valuemin']
    }],
    maxProgressVal: [{
      type: HostBinding,
      args: ['attr.aria-valuemax']
    }],
    currentProgressVal: [{
      type: HostBinding,
      args: ['attr.aria-valuenow']
    }],
    dataPasswordStrength: [{
      type: HostBinding,
      args: ['attr.data-strength']
    }]
  });
})();

/* eslint-disable @angular-eslint/component-selector */
class PasswordStrengthMeterComponent {
  constructor() {
    this.minPasswordLength = 8;
    this.enableFeedback = false;
    this.enableAsync = false;
    this.colors = [];
    this.numberOfProgressBarItems = 5;
    this.strengthChange = new EventEmitter();
    this.baseClass = 'psm';
    this.passwordStrengthMeterService = inject(IPasswordStrengthMeterService);
    this.passwordStrength = null;
    this.feedback = null;
    this.prevPasswordStrength = null;
    this.passwordChangeObservable$ = new Subject();
    this.init();
  }
  ngOnChanges(changes) {
    if (changes['password']) {
      this.passwordChangeObservable$.next(this.password);
    }
  }
  init() {
    this.passwordChangeObservable$.pipe(distinctUntilChanged(), debounceTime(100), switchMap(value => {
      if (!value) {
        return of({
          score: null,
          feedback: null
        });
      }
      if (value && value.length < this.minPasswordLength) {
        return of({
          score: 0,
          feedback: null
        });
      }
      if (this.enableAsync) {
        return this.calculateScoreAsync(value);
      }
      const result = this.calculateScore(value);
      return of(result);
    }), takeUntilDestroyed()).subscribe(result => {
      this.passwordStrength = result.score;
      this.feedback = result.feedback;
      // Only emit the passwordStrength if it changed
      if (this.prevPasswordStrength !== this.passwordStrength) {
        this.strengthChange.emit(this.passwordStrength);
        this.prevPasswordStrength = this.passwordStrength;
      }
    });
  }
  calculateScore(value) {
    if (this.enableFeedback) {
      return this.passwordStrengthMeterService.scoreWithFeedback(value);
    }
    const feedbackResult = {
      score: this.passwordStrengthMeterService.score(value),
      feedback: null
    };
    return feedbackResult;
  }
  calculateScoreAsync(value) {
    if (this.enableFeedback) {
      return this.passwordStrengthMeterService.scoreWithFeedbackAsync(value);
    }
    return this.passwordStrengthMeterService.scoreAsync(value).then(result => ({
      score: result,
      feedback: null
    }));
  }
  static {
    this.ɵfac = function PasswordStrengthMeterComponent_Factory(t) {
      return new (t || PasswordStrengthMeterComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PasswordStrengthMeterComponent,
      selectors: [["password-strength-meter"]],
      hostVars: 2,
      hostBindings: function PasswordStrengthMeterComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.baseClass);
        }
      },
      inputs: {
        password: "password",
        minPasswordLength: "minPasswordLength",
        enableFeedback: ["enableFeedback", "enableFeedback", booleanAttribute],
        enableAsync: ["enableAsync", "enableAsync", booleanAttribute],
        colors: "colors",
        numberOfProgressBarItems: "numberOfProgressBarItems"
      },
      outputs: {
        strengthChange: "strengthChange"
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 4,
      consts: [["role", "progressbar", 1, "psm__progress-bar", 3, "passwordStrength", "numberOfProgressBarItems", "colors"], [1, "psm__progress-bar-items"], [1, "psm__progress-bar-overlay"], ["class", "psm__feedback"], ["class", "psm__suggestion"], [1, "psm__feedback"], [1, "psm__suggestion"]],
      template: function PasswordStrengthMeterComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵelement(1, "div", 1)(2, "div", 2);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(3, PasswordStrengthMeterComponent_Conditional_3_Template, 2, 2);
        }
        if (rf & 2) {
          i0.ɵɵproperty("passwordStrength", ctx.passwordStrength)("numberOfProgressBarItems", ctx.numberOfProgressBarItems)("colors", ctx.colors);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(3, ctx.enableFeedback && ctx.feedback ? 3 : -1);
        }
      },
      dependencies: [PSMProgressBarDirective],
      styles: ["*[_ngcontent-%COMP%], *[_ngcontent-%COMP%]:before, *[_ngcontent-%COMP%]:after{box-sizing:border-box}.psm__progress-bar[_ngcontent-%COMP%]{position:relative;height:3px;margin:10px auto;border-radius:3px;background:#fff}.psm__progress-bar-items[_ngcontent-%COMP%]{display:flex;justify-content:space-evenly;width:100%;height:100%;background:#ddd}.psm__progress-bar-item[_ngcontent-%COMP%]{height:100%;z-index:5;border-right:4px solid #fff}.psm__progress-bar-item[_ngcontent-%COMP%]:last-child{border-right:0px}.psm__progress-bar-overlay[_ngcontent-%COMP%]{position:absolute;background:red;border-radius:3px;height:3px;top:0;transition:width .5s ease-in-out,background .25s}.psm__feedback[_ngcontent-%COMP%], .psm__suggestion[_ngcontent-%COMP%]{font-size:70%;font-weight:400;color:#6c757d!important;display:block;margin-top:.25rem}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PasswordStrengthMeterComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'password-strength-meter',
      imports: [PSMProgressBarDirective],
      template: "<div\n  class=\"psm__progress-bar\"\n  role=\"progressbar\"\n  [passwordStrength]=\"passwordStrength\"\n  [numberOfProgressBarItems]=\"numberOfProgressBarItems\"\n  [colors]=\"colors\"\n>\n  <div class=\"psm__progress-bar-items\"></div>\n  <div class=\"psm__progress-bar-overlay\"></div>\n</div>\n\n@if(enableFeedback && feedback) {\n\n  @if(feedback.warning) {\n    <small class=\"psm__feedback\">\n      {{ feedback.warning }}\n    </small>\n  }\n\n  @if(feedback.suggestions && feedback.suggestions.length) {\n    <small class=\"psm__suggestion\">\n      @for (suggestion of feedback.suggestions; track suggestion; let isLast = $last) {\n        {{ suggestion }}{{ isLast ? '' : ' ' }}\n      }\n    </small>\n  }\n}\n\n",
      styles: ["*,*:before,*:after{box-sizing:border-box}.psm__progress-bar{position:relative;height:3px;margin:10px auto;border-radius:3px;background:#fff}.psm__progress-bar-items{display:flex;justify-content:space-evenly;width:100%;height:100%;background:#ddd}.psm__progress-bar-item{height:100%;z-index:5;border-right:4px solid #fff}.psm__progress-bar-item:last-child{border-right:0px}.psm__progress-bar-overlay{position:absolute;background:red;border-radius:3px;height:3px;top:0;transition:width .5s ease-in-out,background .25s}.psm__feedback,.psm__suggestion{font-size:70%;font-weight:400;color:#6c757d!important;display:block;margin-top:.25rem}\n"]
    }]
  }], () => [], {
    password: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    minPasswordLength: [{
      type: Input
    }],
    enableFeedback: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    enableAsync: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    colors: [{
      type: Input
    }],
    numberOfProgressBarItems: [{
      type: Input
    }],
    strengthChange: [{
      type: Output
    }],
    baseClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/*
 * Public API Surface of password-strength-meter
 */

/**
 * Generated bundle index. Do not edit.
 */

export { IPasswordStrengthMeterService, PSMProgressBarDirective, PasswordStrengthMeterComponent };
