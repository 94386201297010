import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  url = 'https://devbox.abemassry.com/api';

  async getBlockList(api_key: string="", count: number=10) {
    const body = {"api_key": api_key, "count": count};
    return await fetch(`${this.url}/settings/blocklist`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  async deleteBlockApi(api_key: string="", url: string) {
    const body = {"api_key": api_key, "url": url};
    return await fetch(`${this.url}/settings/deleteblock`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  constructor() { }
}
