<div class="footer-main">
  <p class="footer-text">
    <a routerLink="/privacy-policy" routerLinkActive="active" ariaCurrentWhenActive="page">Privacy Policy</a>
    &nbsp; | &nbsp;
    <a routerLink="/terms-of-service" routerLinkActive="active" ariaCurrentWhenActive="page">Terms of Service</a>
    &nbsp; | &nbsp;
    <a routerLink="/contact" routerLinkActive="active" ariaCurrentWhenActive="page">Contact</a>
    &nbsp; | &nbsp;
    <a routerLink="/about" routerLinkActive="active" ariaCurrentWhenActive="page">About</a>
  </p>
</div>