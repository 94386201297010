import { Component } from '@angular/core';
import { RouterLink, RouterOutlet, Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-app-footer',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './app-footer.component.html',
  styleUrl: './app-footer.component.css'
})
export class AppFooterComponent {

}
