import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink, ActivatedRoute, Params } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';

import { CommonModule } from '@angular/common';

import { ProfileService } from './profile.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    RouterLink,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatButtonModule,
    MatTabsModule,
    MatPaginatorModule
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit {

  items: any[] = [];
  api_key = "";
  timeout: any = null;
  length = 50;
  pageSize = 25;
  pageIndex = 0;
  pageSizeOptions = [25, 100, 1000];
  searchTerm = ""

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService

  ) { }
  ngOnInit(): void {
    if (localStorage.getItem('api_key') === null || localStorage.getItem('api_key') === '') {
      this.router.navigate(['/404']);
      return;
    }
    this.api_key = localStorage.getItem('api_key')!;
    this.populatePages();
    this.getPageLength();
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    this.searchTerm = event.target.value;
    var $this = this;
    this.timeout = setTimeout(function () {
      $this.handlePageEvent(event);
      $this.executeSearch(event.target.value);
    }, 1000);
  }

  executeSearch(value: string) {
    this.searchTerm = value;
    this.populatePages();
  }

  async populatePages() {
    const response = await this.profileService.getPages(this.api_key, this.pageSize, this.pageIndex, this.searchTerm);
    const data = await response.json();
    console.log(data);
    if (data.success) {
      this.items = data.pages;
    }
  }

  async getPageLength() {
    const response = await this.profileService.getPageLength(this.api_key, this.searchTerm);
    const data = await response.json();
    console.log(data);
    if (data.success) {
      this.length = data.length;
    }
  }


  deletePage(url: string) {
    this.profileService.deletePageApi(this.api_key, url);
    let itemId = -1;
    let i = 0;
    for (const item of this.items) {
      if (item.url === url) {
        itemId = i;
        break;
      }
      i++;
    }
    if (itemId > -1) {
      this.items.splice(itemId, 1);
    }
  }

  blockPage(url: string) {
    this.profileService.blockPageApi(this.api_key, url);
    let itemId = -1;
    let i = 0;
    for (const item of this.items) {
      if (item.url === url) {
        itemId = i;
        break;
      }
      i++;
    }
    if (itemId > -1) {
      this.items.splice(itemId, 1);
    }
  }

  pageEvent: PageEvent;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageEvent);
    console.log(this.length);
    console.log(this.pageSize);
    console.log(this.pageIndex);
    this.getPageLength();
    this.populatePages();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

}
