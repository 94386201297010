<div class="hero-container mat-typography wrap container-fluid">
  <div class="row start-xs">
    <div class="col-md-offset-3 col-md-6">
      <div class="box">
        <h1 class="hero-text">JourneyAL</h1>
        <br />
        <h6 class="hero-subtext">Look up information in the blink of an eye</h6>
        <h6 class="hero-subtext-sub">Tame your tabs</h6>
        <h6 class="hero-subtext-sub">Organize your online life</h6>
        <h6 class="hero-subtext-sub">Never lose a bookmark</h6>
        <h6 class="hero-subtext-sub">Across multiple devices</h6>
        <br />
        <br />
        <br />
        <form action="https://devbox.abemassry.com/api/create-checkout-session" method="POST" class="home-form">
          <button mat-raised-button extended routerLink='purchase' color="accent" type="submit"
            class="big-mat-button">Purchase</button>
        </form>
        <span>&nbsp;&nbsp;&nbsp;</span>
        <button mat-raised-button extended routerLink='download' type="submit" class="big-mat-button"
          (click)="scrollToElement(download)">Download</button>
        <br />
        <br />
        <br />
        <br />
        <h2 #features id="features">Hosted or Self Host</h2>
        <p class="main-page-text">
          Purchase and sign up for a hosted account or download and self host. Your choice.
        </p>
        <br />
        <br />
        <h2>Install the browser extension</h2>
        <p class="main-page-text">
          Once the browser extension is installed the pages you visit are instally backed up, never worry about losing a
          tab.
          <br />
          Close them with confidence.
          <br />
          To bookmark a page simply bookmark the page using the native browser, that action will let the server know
          (hosted or self host) that you want to add that bookmark. No complex bookmarking steps beyond the native
          browser.
        </p>
        <br />
        <br />
        <h2>Search through your pages, bookmarks, and images</h2>
        <p class="main-page-text">
          This is your data, search through it to find what you're looking for.
          <br />
          Don't remember a specific ingredient in a recipe, an obscure book review, a not often played browser based
          online game? Just search
          <br />
          Search on the hosted site or the self hosted site, your choice.
        </p>
        <br />
        <br />
        <h2 #pricing id="pricing">Pricing</h2>
        <p class="main-page-text">
          $10 One Time Purchase
        </p>
        <br />
        <br />
        <h2 #download id="download">Firefox Extension</h2>
        <br />
        <br />
        <h2>Chrome Extension</h2>
        <br />
        <br />
        <h2>iOS Mobile Safari Extension</h2>
        <br />
        <br />
        <h2>iOS App</h2>
        <br />
        <br />
        <h2>Android App</h2>
        <br />
        <br />
        <h2>Self Host</h2>
      </div>
    </div>
  </div>
</div>