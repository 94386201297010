import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, ActivatedRoute, Params } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';

import { ImagesService } from './images.service';

@Component({
  selector: 'app-images',
  standalone: true,
  imports: [MatSidenavModule, MatListModule, MatIconModule, RouterLink, MatFormFieldModule, MatInputModule, MatButtonModule, MatCardModule, CommonModule, MatPaginatorModule],
  templateUrl: './images.component.html',
  styleUrl: './images.component.css'
})
export class ImagesComponent implements OnInit {
  items: any[] = [];
  api_key = "";
  timeout: any = null;
  length = 50;
  pageSize = 25;
  pageIndex = 0;
  pageSizeOptions = [25, 100, 1000];
  searchTerm = ""

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private imagesService: ImagesService) { }

  ngOnInit(): void {
    if (localStorage.getItem('api_key') === null || localStorage.getItem('api_key') === '') {
      this.router.navigate(['/404']);
      return;
    }
    this.api_key = localStorage.getItem('api_key')!;
    this.populateImages();
    this.getImagesLength();
  }
  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    this.searchTerm = event.target.value;
    var $this = this;
    this.timeout = setTimeout(function () {
      $this.handlePageEvent(event);
      $this.executeSearch(event.target.value);
    }, 1000);
  }
  executeSearch(value: string) {
    this.searchTerm = value;
    this.populateImages();
  }
  async getImagesLength() {
    const response = await this.imagesService.getImagesLength(this.api_key, this.searchTerm);
    const data = await response.json();
    console.log(data);
    if (data.success) {
      this.length = data.length;
    }
  }
  async populateImages() {
    const response = await this.imagesService.getImages(this.api_key, this.pageSize, this.pageIndex, this.searchTerm);
    const data = await response.json();
    console.log(data);
    if (data.success) {
      this.items = data.images;
      let i = 0;
      for (const item of this.items) {
        (async () => {
          this.items[i].src = await this.getImage(item.name);
        })()
        i++;
      }
    }
  }
  async getImage(name: string) {
    return await this.imagesService.getImageApi(this.api_key, name);
  }

  deleteImage(name: string) {
    this.imagesService.deleteImageApi(this.api_key, name);
    let itemId = -1;
    let i = 0;
    for (const item of this.items) {
      if (item.name === name) {
        itemId = i;
        break;
      }
      i++;
    }
    if (itemId > -1) {
      this.items.splice(itemId, 1);
    }
  }


  pageEvent: PageEvent;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageEvent);
    console.log(this.length);
    console.log(this.pageSize);
    console.log(this.pageIndex);
    this.getImagesLength();
    this.populateImages();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

}
