<mat-card class="login-card">
  <mat-card-header class="login-card-header">
    <mat-card-title>Login</mat-card-title>
  </mat-card-header>
  <div class="login-error" *ngIf="errorMessage">
    <p class="login-error-message">{{ errorMessage }}</p>
  </div>

  <mat-card-content>
    <div class="login-selection">
      <form [formGroup]="loginForm" (ngSubmit)="handleSubmit()">
        <mat-form-field class="login-option">
          <mat-label>Email</mat-label>
          <input matInput type="email" placeholder="Enter your email" formControlName="email" required />
        </mat-form-field>

        <mat-form-field class="login-option">
          <mat-label>Password</mat-label>
          <input matInput type="password" placeholder="Enter your password" formControlName="password" required />
        </mat-form-field>

        <div class="login-option">
          <button mat-button color="primary">Login</button>
        </div>
        <div class="login-actions">
          <!-- <button mat-raised-button color="primary" [disabled]="!loginForm.valid">Login</button> -->
          <a mat-button routerLink="/forgot-password">Forgot Password?</a>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>

