import { bootstrapApplication } from '@angular/platform-browser';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideZxvbnServiceForPSM } from 'angular-password-strength-meter/zxcvbn';


import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import {MatNativeDateModule} from '@angular/material/core';
import {provideHttpClient} from '@angular/common/http';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(),
    provideZxvbnServiceForPSM(),
    importProvidersFrom(MatNativeDateModule)
  ]
};

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
