import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink, ActivatedRoute, Params } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { SignupService } from '../../signup/signup.service';
import { SettingsService } from './settings.service';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    RouterLink,
    MatButtonModule
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
export class SettingsComponent implements OnInit {
  signupService: SignupService = inject(SignupService);
  email = '';
  api_key = '';
  items: any[] = [];
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private settingsService: SettingsService) { }
  ngOnInit(): void {
      if (localStorage.getItem('api_key') === null || localStorage.getItem('api_key') === '') {
        this.router.navigate(['/404']);
        return;
      }
    this.api_key = localStorage.getItem('api_key')!;
    this.populateBlockList();

    this.activatedRoute.params.subscribe(async params => {
      this.email = await this.signupService.GetCustomerEmail(localStorage.getItem('api_key')!);
    });
  }

  async populateBlockList() {
    const response = await this.settingsService.getBlockList(this.api_key, 10);
    const data = await response.json();
    console.log(data);
    if (data.success) {
      this.items = data.blocklist;
    }
  }
  
  deleteBlock(url: string) {
    this.settingsService.deleteBlockApi(this.api_key, url);
    let itemId = -1;
    let i = 0;
    for (const item of this.items) {
      if (item.url === url) {
        itemId = i;
        break;
      }
      i++;
    }
    if (itemId > -1) {
      this.items.splice(itemId, 1);
    }
  }

  signOut() {
    localStorage.removeItem('api_key');
    window.location.href = 'https://devbox.abemassry.com';
  }
}
