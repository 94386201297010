import { Component, OnInit, TemplateRef, inject } from '@angular/core';
import { CommonModule, NgIfContext } from '@angular/common';
import { RouterLink, RouterOutlet, Router, ActivatedRoute, ParamMap } from '@angular/router';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatMenuModule } from '@angular/material/menu';

import { AppHeaderService } from './app-header.service';

@Component({
  selector: 'app-app-header',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    FlexLayoutModule,
    MatMenuModule
  ],
  templateUrl: './app-header.component.html',
  styleUrl: './app-header.component.css'
})
export class AppHeaderComponent implements OnInit {
  title = 'header';
  api_key: string | null | undefined = '';
  email: string | null = '';
  appHeaderService: AppHeaderService = inject(AppHeaderService);
  loggedOut: TemplateRef<NgIfContext<string | null>> | null | undefined;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
  ) { }
  ngOnInit(): void {
    const api_key_defined = false;
    const check_api_key = setInterval(() => {
      if (localStorage.getItem('api_key') === null || localStorage.getItem('api_key') === '') {
        return;
      } else {
        clearInterval(check_api_key);
        this.api_key = localStorage.getItem('api_key');
        this.getHeaderAccountInfo();
        return;
      }
    }, 2000)
  }
  async getHeaderAccountInfo() {
    const response = await this.appHeaderService.GetAccountInfo(this.api_key);
    const data = await response.json();
    console.log(data);
    if (data.success) {
      this.email = data.email;
    }
  }
  goPlaces() {
    this.router.navigate(['/', { fragment: 'download' }]);
  }
  isHomePage() {
    const regexp = new RegExp('(\/|\/#[a-z]+)')
    return regexp.test(this.router.url)
  }
}

