import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppHeaderService {
  url = 'https://devbox.abemassry.com/api';

  async GetAccountInfo(api_key: string | null | undefined ='') {
    const body = {'api_key': api_key};
    return await fetch(`${this.url}/get-account-info`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  constructor() { }
}
