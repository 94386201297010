import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  url = 'https://devbox.abemassry.com/api';


  async getPages(api_key: string = "", count: number = 10, pageIndex: number = 0, search_term: string = "") {
    const body = {
      "api_key": api_key,
      "count": count,
      "page_index": pageIndex,
      "search_term": search_term
    };
    return await fetch(`${this.url}/pages`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  async getPageLength(api_key: string = "", search_term: string = "") {
    const body = {
      "api_key": api_key,
      "search_term": search_term
    };
    return await fetch(`${this.url}/pages/length`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  async deletePageApi(api_key: string = "", url: string) {
    const body = { "api_key": api_key, "url": url };
    return await fetch(`${this.url}/pages/delete`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  async blockPageApi(api_key: string = "", url: string) {
    const body = { "api_key": api_key, "url": url };
    return await fetch(`${this.url}/pages/block`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  constructor() { }
}
