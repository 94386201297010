import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SignupService {
  url = 'https://devbox.abemassry.com/api';

  async SubmitSignup(email: string="", password: string="", api_key: string="") {
    const body = {"email": email, "password": password, "api_key": api_key};
    return await fetch(`${this.url}/signup`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  async GetCustomerEmail(sessionId: string="") {
    const body = {"sessionId": sessionId};
    const resp = await fetch(`${this.url}/get-customer-email`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    const json = await resp.json();
    return json.email;
  }

  constructor() { }
}
