import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  url = 'https://devbox.abemassry.com/api';

  async getImageApi(api_key: string = "", name: string = "") {
    const src = `${this.url}/image/${name}`
    const options = {
      headers: {
        'Authorization': 'Bearer ' + api_key
      }
    };

    const res = await fetch(src, options);
    const blob = await res.blob();
    return URL.createObjectURL(blob);
  }

  async getImages(api_key: string = "", count: number = 25, pageIndex: number = 0, search_term: string = "") {
    const body = {
      "api_key": api_key,
      "count": count,
      "page_index": pageIndex,
      "search_term": search_term
    };
    return await fetch(`${this.url}/images`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  async getImagesLength(api_key: string = "", search_term: string = "") {
    const body = {
      "api_key": api_key,
      "search_term": search_term
    };
    return await fetch(`${this.url}/images/length`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  async deleteImageApi(api_key: string = "", name: string) {
    const body = { "api_key": api_key, "name": name };
    return await fetch(`${this.url}/image/delete`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  constructor() { }
}
