<mat-sidenav-container class="example-container" autosize>
	<mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true">
		<mat-nav-list>
            <mat-list-item routerLink='/profile/pages'><mat-icon class="mat-icon mat-list-icon material-icons">article</mat-icon> Pages</mat-list-item>
            <mat-list-item class="profile-highlight" routerLink='/profile/images'><mat-icon class="mat-icon mat-list-icon material-icons">photo_library</mat-icon> Images</mat-list-item>
            <mat-list-item routerLink='/profile/bookmarks'><mat-icon class="mat-icon mat-list-icon material-icons">bookmarks</mat-icon> Bookmarks</mat-list-item>
            <mat-list-item routerLink='/profile/settings'><mat-icon class="mat-icon mat-list-icon material-icons">settings</mat-icon> Settings</mat-list-item>
            <br />
		</mat-nav-list>
    <mat-nav-list>
</mat-nav-list>
	</mat-sidenav>

	<div class="example-sidenav-content">
    <mat-paginator #paginator
             class="demo-paginator"
             (page)="handlePageEvent($event)"
             [length]="length"
             [pageSize]="pageSize"
             [disabled]="disabled"
             [showFirstLastButtons]="showFirstLastButtons"
             [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
             [hidePageSize]="hidePageSize"
             [pageIndex]="pageIndex"
             aria-label="Select page">
    </mat-paginator>
        <form class="example-form">
          <mat-form-field class="example-full-width">
            <mat-label>search</mat-label>
            <input matInput type="text" placeholder="search" formcontrolname="search" (keyup)="onKeySearch($event)" />
          </mat-form-field>
        </form>
        <div class="card-list">
          <mat-list *ngFor="let item of items" class="card-list">
            <mat-card class="example-card">
              <mat-card-header>
                <mat-card-title><a class="page-link" href="{{ item.url }}">{{ item.title }}</a></mat-card-title>
              </mat-card-header>
              <a href="{{ item.url }}"><img mat-card-image src="{{ item.src }}"></a>
              <mat-card-content>
                <p>{{ item.body }}</p>
              </mat-card-content>
              <mat-card-actions>
                <button mat-icon-button aria-label="Delete this page" (click)="deleteImage(item.name)">
                  <mat-icon class="mat-icon mat-list-icon material-icons">delete_forever</mat-icon>
                </button>
              </mat-card-actions>
            </mat-card>

          </mat-list>
        </div>
    <mat-paginator #paginator
             class="demo-paginator"
             (page)="handlePageEvent($event)"
             [length]="length"
             [pageSize]="pageSize"
             [disabled]="disabled"
             [showFirstLastButtons]="showFirstLastButtons"
             [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
             [hidePageSize]="hidePageSize"
             [pageIndex]="pageIndex"
             aria-label="Select page">
    </mat-paginator>
	</div>
</mat-sidenav-container>
